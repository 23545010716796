import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { ForCarersPageTemplate } from "./ForCarersPageTemplate";
import Layout from "../components/Layout";

const ForCarersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout title={frontmatter.meta_title} description={frontmatter.heading}>
      <ForCarersPageTemplate
        title={frontmatter.title}
        description={frontmatter.heading}
        topPanel={frontmatter.topPanel}
        content={frontmatter.content}
      />
    </Layout>
  );
};

ForCarersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ForCarersPage;

export const forCarersPageQuery = graphql`
  query ForCarersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta_title
        title
        heading
        content {
          sections {
            title
            description
            hasButton
            fixedWidthImage {
              childImageSharp {
                gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
              }
              extension
              publicURL
            }
            fullWidthImage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
              extension
              publicURL
            }
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
              extension
              publicURL
            }
          }
        }
      }
    }
  }
`;

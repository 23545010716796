import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { TextSection } from "../../components/molecules/TextSection";
import { TextAndImageSection } from "../../components/molecules/TextAndImageSection";
import { LayoutBox, LayoutBoxMinimal } from "../../components/atoms/LayoutBox";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

export const ForCarersPageTemplate = ({ title, description, content }) => {
  return (
    <>
      <div className="hero-carer is-medium">
        <div className="hero-body pl-6 pr-6">
          <LayoutBoxMinimal>
            <div className="columns is-multiline">
              <div className="column is-9">
                <div className="section p-0 pb-3">
                  <h1
                    className="title is-size-3-mobile is-size-2-tablet is-size-1-widescreen pl-0 pr-0"
                    style={{
                      color: "white",
                      lineHeight: "1.2",
                    }}
                  >
                    {title}
                  </h1>
                </div>
              </div>
              <div className="column is-4">
                <div className="section p-0 pr-6">
                  <h2
                    className="subtitle has-text-weight-normal is-size-5-mobile is-size-5-tablet is-size-4-widescreen pl-0 pr-0"
                    style={{
                      color: "white",
                      lineHeight: "1.4",
                      paddingBottom: "6rem",
                    }}
                  >
                    {description}
                  </h2>
                </div>
              </div>
            </div>
          </LayoutBoxMinimal>
        </div>
      </div>
      {content.sections &&
        content.sections.map((c, idx) => {
          const hasAltBackgroundColor = idx % 2 === 0;
          const isReverseOrder = idx % 2 === 0;

          return c.image || c.fixedWidthImage ? (
            <TextAndImageSection
              title={c.title}
              mainText={c.description}
              image={c.image || c.fixedWidthImage}
              hasAltBackgroundColor={hasAltBackgroundColor}
              isReverseOrder={isReverseOrder}
              hasPortraitImage={true}
            >
              {c.hasButton && (
                <div className="columns is-centered pt-4">
                  <div className="column is-6 pt-0">
                    <Link
                      to="/app-link?install"
                      className="button is-primary is-rounded is-large is-fullwidth"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              )}
            </TextAndImageSection>
          ) : c.fullWidthImage ? (
            <LayoutBox hasAltBackgroundColor={hasAltBackgroundColor}>
              <h1
                className="title is-size-4-mobile is-size-3-tablet is-size-2-widescreen pl-0 pr-0 pb-6"
                style={{
                  lineHeight: "1",
                }}
              >
                {c.title}
              </h1>
              <PreviewCompatibleImage imageInfo={{ image: c.fullWidthImage }} />
              <h2
                className="subtitle has-text-weight-light is-size-6-mobile is-size-6-tablet is-size-5-widescreen pt-6 pl-0 pr-0"
                style={{
                  lineHeight: "1.4",
                }}
              >
                {c.description}
              </h2>
              {c.hasButton && (
                <div className="columns is-centered pt-4">
                  <div className="column is-3 pt-0">
                    <Link
                      to="/app-link?install"
                      className="button is-primary is-rounded is-large is-fullwidth"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              )}
            </LayoutBox>
          ) : (
            <TextSection
              title={c.title}
              mainText={c.description}
              hasAltBackgroundColor={hasAltBackgroundColor}
            >
              {c.hasButton && (
                <div className="columns is-centered pt-4">
                  <div className="column is-3 pt-0">
                    <Link
                      to="/app-link?install"
                      className="button is-primary is-rounded is-large is-fullwidth"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              )}
            </TextSection>
          );
        })}
      <LayoutBox>
        <div className="columns is-centered pt-0">
          <div className="column is-3 pt-0">
            <Link
              to="/app-link?install"
              className="button is-primary is-rounded is-large is-fullwidth"
            >
              Get Started
            </Link>
          </div>
        </div>
      </LayoutBox>
    </>
  );
};

ForCarersPageTemplate.propTypes = {
  title: PropTypes.string,
};
